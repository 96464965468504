<template>
  <div class="section">
    <div class="myMsgDiv" @click="changPic">
      <div class="info" style="display: flex; flex-direction: row; align-items: center;text-align: center; ">
        <div style="flex:1; margin-top:8vw;">
          <van-image class="image" width="21.33333vw" height="21.33333vw" style="margin-bottom: 2.66667vw" round
                     :src="imgUrl"/>
          <div class="username">{{ name }}</div>
          <div class="slogan" >创新让生活变的更加美好！</div>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 2.66667vw;">
<!--    <van-cell title="个人信息" icon="user-circle-o" is-link @click="$router.push('/visitorInfo')"/>-->
    <van-cell title="设置" icon="setting-o" is-link @click="$router.push('setting')"/>
    <div class="vc-tigger" @click="toggleVc"></div>
<!--    <van-cell title="关于我们" icon="warning-o" is-link />-->
<!--    <van-cell title="实验功能" icon="upgrade" is-link @click="$router.push('/qrtest')"/>-->
  </div>

  <div style="width: 5.33333vw;height: 5.33333vw" @click="$router.push('/test/nativePlugins')"></div>
  <div style="margin: 4.26667vw;">
    <van-button round block type="primary" @click="loginOut">
      退出登录
    </van-button>
  </div>

</template>

<script>
import _ from "lodash";

export default {
  name: "My",
  data() {
    return {
      name: '',
      imgUrl: ''
    };
  },
  methods: {
    loginOut: function () {
      this.$sapi.clearUserInfo();
      this.$router.replace('/login')
    },
    changPic: function () {

    },
    // 控制点击十次显示隐藏
    hasClass(obj, cls) {
      return obj.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    },
    addClass(obj, cls) {
      if (!this.hasClass(obj, cls)) obj.className += " " + cls;
    },
    toggleClass(obj,cls){
      if(this.hasClass(obj,cls)){
        this.removeClass(obj, cls);
      }else{
        this.addClass(obj, cls);
      }
    },
    removeClass(obj, cls) {
      if (this.hasClass(obj, cls)) {
        var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        obj.className = obj.className.replace(reg, ' ');
      }
    },
    toggleVc(){
      const nowTime = new Date().getTime();
      if(nowTime - this.lastClickTime < 3000){
        this.count ++;
      } else {
        this.count = 0;
      }
      this.lastClickTime = nowTime;
      if(this.count >= 10) {
        let vconDom = document.getElementById('__vconsole');
        this.toggleClass(vconDom,'show')
        this.count = 0;
      }
    }
  },

  created: function () {
    if (!this.$sapi.isLogin()) {
      this.$sapi.handleTokenError();
      return;
    }
    var me = this;
    var userInfo = me.$sapi.getUserInfo();

    // var option = {
    //   portName: 'tms.TmsDriver.vapp.getNowDriver',
    //   needLoading: false,
    //   successCallback: (data) => {
    //     var entities = data.entities;
    //     if (!_.isEmpty(entities)) {
    //       var entity = entities[0];
    //       this.$sapi.storage.setItemJson('driver', entity)
    //     } else {
    //       this.$dialog.alert({
    //         title: '',
    //         message: '您尚未维护个人身份信息！',
    //       }).then(() => {
    //
    //       });
    //     }
    //   }
    // }
    // this.$sapi.callPort(option);
    // var driver=this.$sapi.storage.getItemJson('driver')
    // var faceIDAttach = driver.faceIDAttach;
    // if (!_.isEmpty(faceIDAttach)) {
    //   this.imgUrl = faceIDAttach[0].url;
    // }
    me.name = userInfo.cnName;
    me.workNum = userInfo.workNum;
  },

}
</script>

<style scoped>
.vc-tigger{
  height: 40px;
}
.firstPageBar {
  --color-primary: #fff;
  --van-nav-bar-background-color: #fff;
  --van-nav-bar-title-text-color: #000;
  --van-nav-bar-text-color: #000;
}

.grid {
  background: beige;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.grid-item {
  flex: 50%;
  padding: 16px 0;
  text-align: center;
}

.setting {
  margin: 10px;
  height: 30px;
  background-color: #6a9bec;
  font-size: large;
  text-align: center;
}

.info {
  color: #fff;
}

.username {
  margin-bottom: 10px;
}

.workNum {
  margin-bottom: 10px;
}

.slogan {
  color: rgba(255, 255, 255, 0.7);
}

.myMsgDiv {
  background: url(../../assets/img/bj.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0px;
  padding: 20px;
  height: 220px;
}

.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}

</style>